import React from "react";
import logo from "./logo.svg";
import "./App.css";

const App = () => {
  return (
    <div className="body">
      <div className="bodyCenter">
        <div class="logo">
          <img src={logo} alt="Clarus" />
        </div>
        <div class="content">
          <p>
            Clarus researches and develops highly scalable and resilient
            algorithms and software systems to build next-generation trusted
            computing platforms.
          </p>
          <p className="contentText">
            With key innovations in ZK Proof systems, Trust Execution
            Environment, parallel computing and hardware-based algorithm
            acceleration, Clarus solves critical challenges in a wide range of
            trusted computing use cases such as AI deep-fake prevention and
            privacy-preserving data marketplaces.
          </p>
          <p className="contentText">
            Clarus is in stealth mode 💻, please stay tuned for more
            information.
          </p>
        </div>

        <div class="bg"></div>
        <div class="lines">
          <div class="line"></div>

          <div class="line" style={{ opacity: 0 }}></div>
          <div class="line"></div>
        </div>
        <div class="copy">
          © Copyright 2023-2024 Clarus Technology Ltd. a Hong Kong company{" "}
          <br /> All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default App;
